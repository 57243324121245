/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.css-lducnp-MuiFormLabel-root-MuiInputLabel-root {
  top: -5px;
}

.ql-container.ql-snow {
  max-height: 350px;
  overflow: auto;
  border-bottom-left-radius:6px ;
  border-bottom-right-radius:6px ;
}

.ql-toolbar.ql-snow{
  border-top-left-radius:6px ;
  border-top-right-radius:6px ;
}

.quill{
  padding-bottom: 4px;
}

ol {
  list-style: auto;
}